import React from "react";
import styles from "./index.module.css";
import {Button, Menu} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {Route, Switch} from "react-router";
import PatientInfoTab from "./EditorTabs/PatientInfoTab";
import PatientFilesTab from "./EditorTabs/PatientFilesTab";
import CureProgramTab from "./EditorTabs/CureProgramTab";
import InteractionHistoryTab from "./EditorTabs/InteractionHistoryTab";
import DeviationTab from "./EditorTabs/Deviation";
import {Patient} from "../dashboard";
import agent from "../../agent";
import PatientTestResultsTab from "./EditorTabs/test/PatientTestResultsTab";
import {Utils} from "../../helpers/Utils";
import {getPatientTitle, PatientTitleCase} from "../../utils/common";


interface Props {
    match?: { params: { id: string }, url: string, path: string };
    history: {
        location: {
            pathname: string;
            state: {
                prevPath: string;
            }
        }
    };
}

interface State {
    activeTab: string,
    prevPath: string,
    patient?: Patient,
}

class PatientEditor extends React.PureComponent<Props, State> {
    state: State = {
        activeTab: 'patientInfo',
        prevPath: '/dashboard/patients',
    };

    getPatientId = (): string => {
        return this.props.match ? this.props.match.params.id : '';
    }

    async componentDidMount() {
        this.setState({patient: await agent.PatientProvider.open(this.getPatientId())});

        const currentTab: string = this.props.history ? this.props.history.location.pathname : '';

        this.setState({
            activeTab: currentTab.split("/")[5],
            prevPath: this.props.history.location.state ? this.props.history.location.state.prevPath : '/dashboard/patients'
        });
    };

    handleItemClick = (name: string): void => {
        this.setState({
            activeTab: name
        });
    };

    render() {
        const activeItem: string = this.state.activeTab;
        const currentPath: string = this.props.match ? this.props.match.url : '';
        const patientId: string = this.getPatientId();

        return (
            <div className={styles.editor}>
                <div className={styles.patientEditorTop}>
                    {this.state.patient && <p style={{fontWeight: "bold"}}>
                        {`${this.state.patient.surname} ${this.state.patient.name} ${this.state.patient.middlename}`}</p>}
                    <Link to={this.state.prevPath}>
                        <Button
                            className={styles.button}
                            color={'grey'}
                            type={'submit'}
                            content={'Назад'}
                        />
                    </Link>
                </div>
                <Menu stackable>

                    <Menu.Item
                        as={Link}
                        to={`${currentPath}/patientInfo`}
                        content={`Данные ${getPatientTitle(PatientTitleCase.a)}`}
                        active={activeItem === 'patientInfo'}
                        onClick={() => {
                            this.handleItemClick('patientInfo');
                        }}
                    />

                    <Menu.Item
                        as={Link}
                        to={`${currentPath}/cureProgram`}
                        content={Utils.vomode() ? 'Программа поддержки' : 'Программа лечения'}
                        active={activeItem === 'cureProgram'}
                        onClick={() => {
                            this.handleItemClick('cureProgram');
                        }}
                    />

                    <Menu.Item
                        as={Link}
                        to={`${currentPath}/patientFiles`}
                        content={`Файлы ${getPatientTitle(PatientTitleCase.a)}`}
                        active={activeItem === 'patientFiles'}
                        onClick={() => this.handleItemClick('patientFiles')}
                    />
                    {!Utils.vomode() &&
                    <Menu.Item
                        as={Link}
                        to={`${currentPath}/patientTestResults`}
                        content={Utils.vomode() ? 'Документы' : 'Анализы'}
                        active={activeItem === 'patientTestResults'}
                        onClick={() => this.handleItemClick('patientTestResults')}
                    />}

                    <Menu.Item
                        as={Link}
                        to={`${currentPath}/interactionHistory`}
                        content={'История взаимодействия'}
                        active={activeItem === 'interactionHistory'}
                        onClick={() => this.handleItemClick('interactionHistory')}
                    />

                    <Menu.Item
                        as={Link}
                        to={`${currentPath}/deviation`}
                        content={'Отклонения'}
                        active={activeItem === 'deviation'}
                        onClick={() => this.handleItemClick('deviation')}
                    />
                </Menu>

                <Switch>
                    <Route path={`${currentPath}/patientInfo`}>
                        <PatientInfoTab id={patientId} match={this.props.match} backUrl={this.state.prevPath}/>
                    </Route>
                    <Route path={`${currentPath}/cureProgram`}>
                        <CureProgramTab id={patientId}/>
                    </Route>
                    <Route path={`${currentPath}/patientFiles`}>
                        <PatientFilesTab id={patientId}/>
                    </Route>
                    <Route path={`${currentPath}/patientTestResults`}>
                        <PatientTestResultsTab patientId={patientId}/>
                    </Route>
                    <Route path={`${currentPath}/interactionHistory`}>
                        <InteractionHistoryTab patientId={patientId}/>
                    </Route>
                    <Route path={`${currentPath}/deviation`}>
                        <DeviationTab patientId={patientId}/>
                    </Route>
                </Switch>

            </div>
        );
    }
}

export default PatientEditor;
