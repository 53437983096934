import React from "react";
import {Doctor, InteractionHistory} from "../../dashboard";
import agent from "../../../agent";
import {Button, Form, Grid, Header, Loader, Segment, TextArea} from "semantic-ui-react";
import styles from "../index.module.css";
import {DoctorTitleCase, getDoctorTitle, getPatientTitle, PatientTitleCase} from "../../../utils/common";
import {DoctorPaginationResponse} from "../../workTableDoctors/DoctorsList";

interface Props {
    patientId: string;
}

interface State {
    loading: boolean,
    interactionHistoryList: InteractionHistory[],
    editedElementIndex: number | undefined,
    editedComment: string | undefined,
    doctors: Doctor[]
}


class InteractionHistoryTab extends React.PureComponent<Props, State> {
    state: State = {
        loading: true,
        interactionHistoryList: [],
        editedElementIndex: undefined,
        editedComment: undefined,
        doctors: []
    };

    async componentDidMount() {
        await this.onMount();
    };

    onMount = async (): Promise<void> => {
        const list: InteractionHistory[] = await agent.PatientProvider.getInteractionHistory(this.props.patientId);
        const response: DoctorPaginationResponse = await agent.DoctorProvider.getList();
        console.log(response.docs)
        this.setState({
            interactionHistoryList: list,
            loading: false,
            doctors: response.docs
        });
    };

    getDoctorFullName = (doctorId: string): string => {
        if (doctorId==='000000000000000000000001') {
            return 'Администратор'
        } else {
            const doctor: Doctor | undefined = this.state.doctors.find((el: Doctor) => el._id === doctorId);
            return doctor ? `${doctor.surname} ${doctor.name} ${doctor.middlename}` : '';}
    };

    render() {
        return (
            this.state.loading ?
                <Loader active/>
                :
                <div className={styles.containerInteractionHistory}>
                    <Grid stackable stretched>

                        <Grid.Column floated={"right"} width={2}>
                            {
                                <Button
                                    size={'small'}
                                    color={'green'}
                                    type={'submit'}
                                    content={'Добавить'}
                                    onClick={async () => {
                                        this.setState({loading: true});
                                        const list: InteractionHistory[] = await agent.PatientProvider
                                            .createInteractionHistory(this.props.patientId);
                                        this.setState({
                                            interactionHistoryList: list,
                                            loading: false
                                        });
                                    }}
                                />
                            }
                        </Grid.Column>
                    </Grid>
                    {this.state.interactionHistoryList.map((el, index) => {
                        return <Segment color={el.manual ? "purple" : undefined}>
                            <Header>
                                Создано: {(el.manual && el.userCreate) ? this.getDoctorFullName(el.userCreate)+', ' : ''}{new Date(el.date).toLocaleString()}
                                <br/>
                                {(el.dateUpdate && el.userUpdate && el.dateUpdate!==el.date) ?
                                `Отредактированно: ${el.userUpdate ? this.getDoctorFullName(el.userUpdate) : ''}, ${new Date(el.dateUpdate).toLocaleString()}` : ''}
                            </Header>
                            {this.state.editedElementIndex !== index ?
                                <Form>
                                    <TextArea
                                        value={el.comment}
                                        onFocus={() => {
                                            this.setState({
                                                editedElementIndex: index,
                                                editedComment: el.comment
                                            });
                                        }}
                                    />
                                </Form>
                                :
                                <Form>
                                    <TextArea
                                        value={this.state.editedComment}
                                        onChange={(e) => {
                                            this.setState({editedComment: e.currentTarget.value});
                                        }}
                                        style={{marginBottom: '10px'}}
                                    />
                                    <Button type={"submit"} primary onClick={() => {
                                        agent.PatientProvider
                                            .editInteractionHistory(el.id, this.state.editedComment!)
                                            .finally(() => {
                                                    this.setState({
                                                        editedComment: undefined,
                                                        editedElementIndex: undefined
                                                    });
                                                    this.onMount();
                                                }
                                            );
                                    }}>Сохранить</Button>
                                    <Button onClick={() => {
                                        this.setState({editedComment: undefined, editedElementIndex: undefined});
                                    }}>Отменить</Button>
                                </Form>
                            }
                        </Segment>
                    })}
                </div>
        )
    }
}

export default InteractionHistoryTab;
